<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >

    <base-material-card
      icon="mdi-bell"
      title="Notifications List"
      class="px-5 py-3"
    >
      <v-simple-table>
        <filter-list
          :filters="filter"
          :on-change="getNotifications">
        </filter-list>
        <tbody>
          <tr v-for="notification in notifications">
            <td>{{ notification.notification_id }}</td>
            <td>{{ notification.duration | dateDuration }}</td>
            <td>{{ notification.notification_text }}</td>
            <td>{{ notification.notification_order }}</td>
            <td>
              <div class="d-inline-flex">
              <v-btn color="ma-2 info float-right" fab x-small dark :to="'/notifications/create-change-request/' + notification.notification_id">
                <v-icon dark>mdi-account-edit</v-icon>
              </v-btn>

              <v-btn color="ma-2 info float-right" fab x-small dark :to="'/notifications/change-requests/' + notification.notification_id">
                <v-icon dark>mdi-view-list</v-icon>
              </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>

<script>
  import FilterList from '../../components/base/FilterList'

  export default {
    name: 'NotificationsList',
    components: { FilterList },
    watch: {
      $route () {
        this.getUrlParams()
        this.getNotifications()
      },
    },
    data () {
      return {
        notifications: [],
        query: {
        },
        filter: [
          {
            name: 'notification_id',
            value: '',
            type: 'text_field',
          },
          {
            name: 'duration',
            value: '',
            type: 'text_field',
          },
          {
            name: 'notification_text',
            value: '',
            type: 'text_field',
          },
          {
            name: 'notification_order',
            value: '',
            type: 'text_field',
          }
        ]
      }
    },
    created () {
      this.getNotifications()
    },
    methods: {
      getUrlParams () {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
          }
          this.filter = [
            {
              name: 'notification_id',
              value: '',
              type: 'text_field',
            },
            {
              name: 'duration',
              value: '',
              type: 'text_field',
            },
            {
              name: 'notification_text',
              value: '',
              type: 'text_field',
            },
            {
              name: 'notification_order',
              value: '',
              type: 'text_field',
            }
          ]
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query
          this.filter.forEach(param => {
            param.value = this.query[param['name']]
          })
        }
      },
      getNotifications () {
        this.$authApi.get('/partner-notifications', {
          params:this.query
        }).then(res => {
          this.notifications = res.data.data
        })
      },
    },
  }
</script>

<style scoped>

</style>
